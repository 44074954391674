/*eslint complexity: ["error", 13]*/

var site = site || {};
var generic = generic || {};
var Mustache = Mustache || {};
site.account = site.account || {};
var loyaltySettings = typeof Drupal.settings.loyalty === 'object' ? Drupal.settings.loyalty : {};
var loyaltySignupPopup = loyaltySettings.signup_popup || {};
(function($) {
  window.site = site || {};
  site.offers = site.offers || {};
  site.offers.loyaltyOffer = site.offers.loyaltyOffer || {};

  // --------
  // Primary behavior responsible for initializing the loyalty offer logic

  Drupal.behaviors.ELB_loyalty_offer = {
  // debug config
    debug: false, // when debugging, the overlay opens on all page loads

    // storage cookie name
    offerCookie: 'clloyalty15off',
    offerCookieExpire: 'clloyalty15off_expire',

    // # of seconds before the overlay appears
    defaultTimeout: 5000,

    // template path config
    templates: {
      form: 'loyalty_offer_signup',
      form1: 'loyalty_offer_valid_unused',
      form2: 'loyalty_offer_valid_used',
      form3: 'loyalty_offer_invalid_unused',
      form4: 'loyalty_offer_invalid_used',
      form5: 'loyalty_offer_add_to_bag',
      form6: 'loyalty_offer_redirect_signin',
      error: 'loyalty_offer_error',
    },

    excludeURL: '/elistemail',

    // overlay config
    overlay: {
      pc: {
        className: 'loyalty-offer-overlay',
        transition: 'none'
      },
      mobile: {
        className: 'loyalty-offer-overlay',
        width: '100%',
        height: '100%', //height: "1000px",
        innerHeight: '100%',
        scrolling: true,
        opacity: '0.9',
        transition: 'none'
      }
    },

    isStr: function(str) {
      return typeof str === 'string';
    },

    isFunc: function(func) {
      return typeof func === 'function';
    },

    isObj: function(mixed_var) {
      if (Object.prototype.toString.call(mixed_var) === '[object Array]') {
        return false;
      }
      return mixed_var !== null && typeof mixed_var === 'object';
    },

    /**
   * Helper function to get the raw contents of an JS inline template, and optionally interpolate
   * the html using Mustache
   * @param {object} key key
   * @param {object} data data
   * @returns {boolean} returns true/false
   */
    getTemplateContent: function(key, data) {
      // Sanitize the incoming data
      data = data || false;

      // Get the html content of the template
      var html = $('script.inline-template[path=\'' + key + '\']').html();

      if (!html || html.length === 0) {
        return $();
      }

      // If there's no data object passed in, then just return a basic jquery element
      if (data === false || !$.isFunction(Mustache.render)) {
        return $(html);
      }

      // We have html and data, which means we should use Mustache to render the output
      return $(Mustache.render(html, data));
    },

    // Open a colorbox window
    openWindow: function(content, callBacks) {
      var self = this;
      content = self.isObj(content) ? content : $();

      // Dumb trick to get the entire contents of the html out of the jquery object
      content = content.wrap('<div />').parent().html();

      if (!$.isFunction($.colorbox)) {
        return false;
      }

      var args = {
            html: content,
            scrolling: false
          },
          darg = $('body').hasClass('device-mobile') ? self.overlay.mobile : self.overlay.pc;
      $.extend(args, darg);

      if (self.isObj(callBacks)) {
        for (var k in callBacks) {
          var func = callBacks[k];
          if (self.isFunc(func)) {
            args[k] = func;
          }
        }
      }

      $.colorbox(args);
    },

    debugLoyaltyForm: function() {
      var self = this;
      // remove
      var qs = (function(a) {
        if (a === '') {
          return {};
        }
        var b = {};
        for (var i = 0; i < a.length; ++i) {
          var p = a[i].split('=', 2);
          if (p.length === 1) {
            b[p[0]] = '';
          } else {
            b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, ' '));
          }
        }
        return b;
      })(window.location.search.substr(1).split('&'));

      var loyaltyForm = qs['lp'];
      if (!_.isUndefined(loyaltyForm) && loyaltyForm === 0) {
        loyaltyForm = self.templates.form;
      } else if (!_.isUndefined(loyaltyForm) && loyaltyForm === 1) {
        loyaltyForm = self.templates.form1;
      } else if (!_.isUndefined(loyaltyForm) && loyaltyForm === 2) {
        loyaltyForm = self.templates.form2;
      } else if (!_.isUndefined(loyaltyForm) && loyaltyForm === 3) {
        loyaltyForm = self.templates.form3;
      } else if (!_.isUndefined(loyaltyForm) && loyaltyForm === 4) {
        loyaltyForm = self.templates.form4;
      } else if (!_.isUndefined(loyaltyForm) && loyaltyForm === 6) {
        loyaltyForm = self.templates.form6;
      } else {
        loyaltyForm = false;
      }

      return loyaltyForm;
    },

    // Show the signup form
    showSignupForm: function() {
      var self = this;
      setTimeout(function() {
        self.showSignupFormNow();
      }, self.defaultTimeout);
    },

    // Show the signup form immediately
    showSignupFormNow: function() {
      var self = this;
      var debugForm = self.debugLoyaltyForm();
      var content;
      var className;
      if (debugForm) {
        content = self.getTemplateContent(debugForm);
        className = debugForm;
      } else if (self.debug) {
        content = self.getTemplateContent(self.templates.form);
        className = self.templates.form;
      } else {
        content = self.getTemplateContent(self.templates.form);
        className = self.templates.form;
      }
      // There is an input that creates a div that we can grab and specify an alternate layout class for i224733
      var useAltMarkUp = $(content).find('#USE_ALT_LAYOUT').length;
      if (useAltMarkUp) {
        var cboxArgs;
        cboxArgs = $('body').hasClass('device-mobile') ? self.overlay.mobile : self.overlay.pc;
        cboxArgs.className = 'loyalty-offer-overlay alt_layout1';
        cboxArgs.fixed = true;
      }

      // Add timer functionality to popup. If no user interaction after X seconds, close.
      var timer;
      var closeTime = 10000;

      function startTimer() {
        timer = setTimeout(function() {
          $.colorbox.close();
        }, closeTime);
        $(document).on('click keypress mousemove', '.loyalty_popover', function() {
          resetTimer();
        });
      }

      function clearTimer() {
        clearTimeout(timer);
        $(document).off('click keypress mousemove', '.loyalty_popover', function() {
          resetTimer();
        });
      }

      function resetTimer() {
        clearTimeout(timer);
        timer = setTimeout(function() {
            $.colorbox.close();
        }, closeTime);
      }

      self.openWindow(content, {

      // Hide the content and overlay in onOpen, so we can fade it in initSignUpForm
      // This prevents "sliding" while the overridden css is loading, and also makes the overlay and content fade in simultaneously
        onOpen: function() {
          $('.loyalty-offer-overlay #cboxContent,#cboxOverlay').css('visibility', 'hidden'); $('body').addClass(className);
          startTimer();
        },
        onLoad: function() {
          var horizontal_class = content.data('loyalty-popup-horizontal-align');
          var vertical_class = content.data('loyalty-popup-vertical-align');
          if (horizontal_class || vertical_class) {
            $('#colorbox').addClass('loyalty-popup-custom-alignment');
          }
          if (horizontal_class) {
            $('#colorbox').addClass(horizontal_class);
          }
          if (vertical_class) {
            $('#colorbox').addClass(vertical_class);
          }
        },
        onCleanup: function() {
          clearTimer();
        },
        onComplete: site.offers.loyaltyOffer.initSignUpForm
      });
    },
    getQueryParamValue: function ( field, url ) {
      var href = url ? url : window.location.href;
      var reg = new RegExp( '[?&]' + field + '=([^&#]*)', 'i' );
      var string = reg.exec(href);
      return string ? string[1] : null;
    },
    /**
   * Helper function to determine if the loyaltyOffer offer should appear on this page
   * @TODO: this will likely be expanded to be disabled on checkout and whatnot
   * @return {boolean} returns true/false
   */
    showOfferOnPage: function() {
      var self = this,
          cookieName = self.offerCookie,
          cookieExpire = self.offerCookieExpire,
          hasCookie = $.cookie(cookieName),
          hasExpiration = $.cookie(cookieExpire),
          showOffer = false;
      var datetime = new Date();

      // adding to allow manual disabling of the popover

      if (!hasCookie) {
      // there is a div in the mustache that gets printed based on the disabled checbox input loyalty_offer_signup_v1
        var markUp = self.getTemplateContent(self.templates.form);
        var markUpDisabled = $(markUp).find('#DISABLE_POPOVER');

        // set the session cookie if the page is disabled, rather than checking every time
        if (markUpDisabled.length > 0) {
          $.cookie(cookieName, '1', {
            path: '/'
          });
          return false;
        }

        // PHF 20160106 i258932: Disable pop up based on tag anywhere on page, leave no cookie
        markUpDisabled = $('#DISABLE_POPOVER');
        if (markUpDisabled.length > 0) {
          return false;
        }
      }

      var persistentCookie = Drupal.ELB.getJSONCookie('persistent_user_cookie');
      var hasLoyalty = persistentCookie.is_loyalty_member - 0;

      if (!hasLoyalty && !hasCookie) {
        showOffer = true;
        // unrecognized and expires in 3 days
        $.cookie(cookieName, '1', {
          expires: 3,
          path: '/'
        });
        // set expiration for retrieval
        datetime = datetime.getTime();
        $.cookie(cookieExpire, datetime, {
          path: '/'
        });
      } else if (!hasLoyalty && hasCookie) {
        showOffer = false;
        // check expiration
        var timecheck = new Date();
        timecheck = timecheck.getTime();
        if (hasExpiration) {
          $.cookie(cookieName, '1', {
            expires: 3,
            path: '/'
          });
          // set expiration for retrieval
          datetime = datetime.getTime();
          $.cookie(cookieExpire, datetime, {
            path: '/'
          });
          var days = (timecheck - hasExpiration) / 1000 / 60 / 60 / 24;
          if (days >= 3) {
            showOffer = true;
            $.cookie(cookieName, '1', {
              expires: 3,
              path: '/'
            });
            // reset expiration
            $.cookie(cookieExpire, timecheck, {
              path: '/'
            });
          }
        } else if (!hasExpiration) {
        // for some reason no expiration cookie
        // set it as backup
          $.cookie(cookieExpire, timecheck, {
            path: '/'
          });
        }
      } else if (hasLoyalty) {
      // if loyalty do not show offer
        showOffer = false;
        $.cookie(cookieName, '1', {
          path: '/'
        });
        // delete expiration
        $.cookie('cookieExpire', null);
      }

      if (self.getQueryParamValue('raf') !== null) {
        showOffer = false;
      }

      var popupShowAtVisitCount = Drupal.settings.globals_variables.popup_show_at_visit_count || false;
      if (popupShowAtVisitCount) {
        showOffer = false;
        site.elcEvents.addListener('tealium:displayWelcomeLoyaltyTag', function() {
          if (parseInt(site.trackingDataLayer.data['cp.utag_main__pn']) === popupShowAtVisitCount) {
            self.showSignupForm();
          }
        });
      }
      return showOffer;
    },

    sortLoyaltyForm: function(userinfo) {
      var self = this;
      var templates = self.templates;
      var content = '';

      //var isLoyaltyMember = userinfo.is_loyalty_member;
      var isRegistered = userinfo.registered_user;
      var offerUsed = 0;
      try {
        offerUsed = userinfo.offers.first.used;
        offerUsed = _.isNull(offerUsed) || _.isUndefined(offerUsed) ? 0 : offerUsed;
      } catch (e) {
        // empty
      }

      if (isRegistered && !offerUsed) { // valid unused
        if (userinfo?.newUser) {
          content = templates.form6;
        } else {
          content = templates.form1;
        }
      } else if (isRegistered && offerUsed) { // valid_used
        content = templates.form2;
      } else if (!isRegistered && !offerUsed) { // invalid_unused
        content = templates.form3;
      } else if (!isRegistered && offerUsed) { // invalid_used
        content = templates.form4;
      } else {
      // not that this should happen
      // fall back, just in case
        content = templates.form;
      }
      return content;
    },

    showOfferOnClickAddToBag: function() {
      var self = this;
      var cookieName = self.offerCookie;
      var hasCookie = $.cookie(cookieName);
      var persistentCookie = Drupal.ELB.getJSONCookie('persistent_user_cookie');
      var hasLoyalty = parseInt(persistentCookie.is_loyalty_member);
      var cookieNameAddToBag = 'clloyalty15off_addToBag';
      var cookieAddToBagExpire = 'clloyalty15off_addToBag_expire';
      var hasCookieAddToBag = $.cookie(cookieNameAddToBag);
      var hasExpirationCookieAddToBag = $.cookie(cookieAddToBagExpire);
      var showOffer = false;
      var datetime = new Date();
      var undef;
      datetime = datetime.getTime();

      var checkCookiesAndLoyalty = self.checkCookiesAndLoyalty(hasLoyalty, hasCookie, hasCookieAddToBag, undef);
      if (checkCookiesAndLoyalty) {
        showOffer = true;
        self.setCookiesAddToBag(cookieNameAddToBag, cookieAddToBagExpire, datetime);
      } else {
        showOffer = false;
        if (hasExpirationCookieAddToBag) {
          self.setCookiesAddToBag(cookieNameAddToBag, cookieAddToBagExpire, datetime);
          var days = (datetime - hasExpirationCookieAddToBag) / 1000 / 60 / 60 / 24;
          if (days >= 3) {
            showOffer = true;
            self.setCookiesAddToBag(cookieNameAddToBag, cookieAddToBagExpire, datetime);
          }
        } else {
          $.cookie(cookieAddToBagExpire, datetime, {
            path: '/'
          });
        }
      }

      if (hasLoyalty) {
        showOffer = false;
        $.cookie(cookieNameAddToBag, '1', {
          path: '/'
        });
        $.cookie(cookieAddToBagExpire, null);
      }
      return showOffer;
    },

    checkCookiesAndLoyalty: function(hasLoyalty, hasCookie, hasCookieAddToBag, undef) {
      if (!hasLoyalty && hasCookie && hasCookieAddToBag === undef) {
        return true;
      } else if (!hasLoyalty && hasCookie && hasCookieAddToBag) {
        return false;
      }
      return true;
    },

    setCookiesAddToBag: function(cookieNameAddToBag, cookieAddToBagExpire, datetime) {
      $.cookie(cookieNameAddToBag, '1', {
        expires: 3,
        path: '/'
      });
      $.cookie(cookieAddToBagExpire, datetime, {
        path: '/'
      });
    },

    initiateAddToBagPopUp: function(context) {
      var self = this;
      if (self.showOfferOnClickAddToBag()) {
        var content = self.getTemplateContent(self.templates.form5);
        self.openWindow(content, {
          onOpen: function() {
            $('.loyalty-offer-overlay #cboxContent,#cboxOverlay').css('visibility', 'hidden');
            $('body').addClass(self.templates.form5);
          },
          onComplete: site.offers.loyaltyOffer.initSignUpForm
        });
      }
    },

    // Main function to get things kicked off
    initiateLoyaltyEnrollmentPopups: function(context) {
      var self = this;
      var userinfo;

      // bail on excluded URL
      if (window.location.pathname === self.excludeURL) {
        return;
      }

      // If the user hasn't seen the popup, then show it to them
      if (self.showOfferOnPage()) {
        this.showSignupForm();
      } else {
      // check if it is the homepage
        if (!$.cookie('FeedTrayCookie')) {
          $.cookie('FeedTrayCookie', 1, {
            expires: 3
          });
          var $myFeedTray = $('.my-feed-tray--loyalty');
          if ($myFeedTray.length) {
            setTimeout(function() {
              site.drawers.open($('.my-feed-drawer .drawer-formatter__trigger'), $myFeedTray.parents('.drawer-formatter__content'), 0, true);
              setTimeout(function() {
                site.drawers.close($myFeedTray.parents('.drawer-formatter__content'));
              }, 10 * 1000); //site.specialOffers.footerDisplayDuration = 10
            }, 60);
          }
        } else {
          return false;
        }
        //roll up my feed
        // borrowed from defunct special_offers.js
      }

      // Bind to the email_signup.success event
      // When a user does the email sign up in the footer, we'll show them the loyaltyOffer overlay
      // if the offer is still valid, and they haven't signed up already
      $(context).on('email_signup.success', function(event, rpcResponse) {
        var response = rpcResponse.getData();
        userinfo = response.userinfo || {};
        var content = self.sortLoyaltyForm(userinfo);
        if (content.length > 0) {
        // @TODO: triggering an overlay will close the special-offers-tray. We don't want it to do that.
          var html = self.getTemplateContent(content);
          self.openWindow(html);
        }
      });

      // Bind to the join_loyalty.success event
      $(context).on('join_loyalty.success', function(event, userinfo) {
        userinfo = userinfo || {};
        var content = self.sortLoyaltyForm(userinfo);

        if (content.length > 0) {
        // @TODO: triggering an overlay will close the special-offers-tray. We don't want it to do that.
          var html = self.getTemplateContent(content);
          self.openWindow(html);
        }
      });

      // Bind to the email_signup.error event for no particular reason except that it exists
      // Do we need to show an error message?
      $(context).on('email_signup.error', function() {
      });

      // Bind to the cbox_complete event. This fires anytime a colorbox window is opened.
      // This event handler adds the disclaimer text to the overlays, but could be used for similiar tasks as well. // no disclaimer
      $(context).on('cbox_complete', function() {
        // empty
      });

      $(context).on('cbox_cleanup', function() {
        var $response;
        $response = $('.loyalty_popover--response');
        if ($response.length) {
        //hook up close button to refresh
          location.reload();
        }
      });

      $(document).on('loyalty.show_signup_form', function() {
        Drupal.behaviors.ELB_loyalty_offer.showSignupFormNow();
      });

      $(document).on('click', '.js-benefits-button', function() {
        var loyalty_offer_signup = Drupal.behaviors.ELB_loyalty_offer.templates.form;
        var content = Drupal.behaviors.ELB_loyalty_offer.getTemplateContent(loyalty_offer_signup);
        Drupal.behaviors.ELB_loyalty_offer.openWindow(content, {
          onOpen: function() {
            $('.loyalty-offer-overlay #cboxContent,#cboxOverlay').css('visibility', 'hidden');
            $('body').addClass(loyalty_offer_signup);
          },
          onComplete: site.offers.loyaltyOffer.initSignUpForm
        });
      });

      $(document).on('click', '.js-shopping-button', function() {
        $.colorbox.close();
      });
    },

    loyaltyOfferAddToBagPopUp: function() {
      Drupal.behaviors.ELB_loyalty_offer.initiateAddToBagPopUp();
    },

    // Main function to get things kicked off
    attach: function(context, settings) {
      if (Drupal.settings.globals_variables.has_language_selection_and_loyalty_popups) {
        if ($.cookie('CA_interstitial')) {
          this.initiateLoyaltyEnrollmentPopups(context, settings);
        }
        return false;
      } else {
        this.initiateLoyaltyEnrollmentPopups(context, settings);
      }

      $(document).on('click', '.js-add-to-bag-button, .js-btn-add-to-bag', function() {
        if (Drupal.settings.enable_new_loyalty_add_to_bag_pop_up) {
          Drupal.behaviors.ELB_loyalty_offer.loyaltyOfferAddToBagPopUp();
        }
      });
    }

  };

  // --------
  // Callbacks and whatnot

  site.offers.loyaltyOffer = {
    behavior: Drupal.behaviors.ELB_loyalty_offer,

    getOverlayContent: function() {
      return $('#cboxLoadedContent');
    },

    triggerLoyaltyEnrollmentTemplates: function(userinfo) {
      var self = site.offers.loyaltyOffer,
          overlay = self.getOverlayContent();

      var b = self.behavior,
          t = b.templates,
          c = t.error, // Default template to return
          e = true, // Defaults to the error condition
          d = { message: "<p>There's been an error.</p>" }; // Error message

      var isRegistered = userinfo.registered_user,
          passwordPopup = false;
      var offerUsed = _.isNull(userinfo.offers) || _.isUndefined(userinfo.offers) ? 0 : userinfo.offers.first.used;

      if (isRegistered && !offerUsed && userinfo?.newUser) { // redirect valid user unused
          c = t.form1;
          passwordPopup = false;
      } else if (isRegistered && !offerUsed) { // valid unused
        c = t.form6;
      } else if (isRegistered && offerUsed) { // valid_used
        c = t.form2;
      } else if (!isRegistered && !offerUsed) { // invalid_unused
        c = t.form3;
        passwordPopup = true;
      } else if (!isRegistered && offerUsed) { // invalid_used
        c = t.form4;
      }

      // And make sure there's no error to show
      e = false;
      // Remove any existing content, and show the error/thanks/already_signed_up template
      overlay
        .empty()
        .append(e ? b.getTemplateContent(c, d) : b.getTemplateContent(c));
      // Act like we're opening a new colorbox
      $('body').addClass(c);
      $(document).trigger('cbox_complete');
      if (site && site.elcEvents) {
        // MTA-4861 Trigger overlay display event when pop up displays on clicking Join Now button from offer page
        if (window.document.readyState === 'complete' && typeof utag !== 'undefined') {
          $(document).trigger('welcome_overlay_displayed', [{ template_name: b.templates.form }]);
        } else {
          // MTA-4861 Trigger overlay display event when pop up displays on page load
          site.elcEvents.addListener('tealium:loaded', function () {
            $(document).trigger('welcome_overlay_displayed', [{ template_name: b.templates.form }]);
          });
        }
      }

      if (passwordPopup === true) {
        var careLoginEnabled = Drupal.settings.globals_variables.care_login;
        if (careLoginEnabled) {
          overlay.find('#js-care-login').removeClass('hidden');
          // add email address to form field
          overlay.find('#js-care-login #js-care-login-signup-email').val(userinfo.pc_email_address);
        } else {
          overlay.find('#js-enter-password').removeClass('hidden');
          this.initPasswordForm();
        }
      }
      //Resize the colorbox to accomodate the new content
      $.colorbox.resize();
    },

    /**
   * Initialize the signup password form
   * @return {boolean} returns boolean
   */
    initPasswordForm: function() {
      var self = site.offers.loyaltyOffer,
          overlay = self.getOverlayContent();
      site.account.initPasswordValidationTooltip();

      overlay.find('form').on('submit', function(e) {
        e.preventDefault();
        var form = $(this),
            fields = form.find('input'),
            paramObj = {};

        $.each(fields, function(index, field) {
          paramObj[field.name] = field.type === 'checkbox' && !field.checked ? '' : field.value;
        });

        // submit form
        generic.jsonrpc.fetch({
          method: 'rpc.form',
          params: [paramObj],
          onSuccess: function(jsonRpcResponse) {
            var response = jsonRpcResponse.getData(),
                userinfo = response.userinfo || {};
            userinfo.newUser = 1;
            site.offers.loyaltyOffer.triggerLoyaltyEnrollmentTemplates(userinfo);
            $.colorbox.resize();
          },
          onFailure: function(jsonRpcResponse) {
            var messages = jsonRpcResponse.getMessages();
            site.rb.language = generic.rb('language');
            var password_error = site.rb.language.get('password_error');
            var proceed;

            // Get the error messages from the rpc response, and use them in the error template
            var errorArr = [],
                output = '';
            proceed = 1;

            $.each(messages, function(i, message) {
              errorArr.push(message.text);
              if (message.key === 'required.password.loyalty_registration' || message.key === 'min_max.password.loyalty_registration' || message.key === 'required.accepted_privacy_policy.loyalty_registration' || message.key === 'format.password.loyalty_registration') {
                proceed = 0;
              }
            });

            if (errorArr.length > 0) {
              output = '<p>' + errorArr.join('<br />') + '</p>';
            }

            if (proceed) {
            // Populate the template with an error message
              var loyalty_offer = self.behavior,
                  templateError = loyalty_offer.templates.error,
                  errorMessage = { message: output.length > 0 ? output : password_error };

              overlay
                .empty()
                .append(loyalty_offer.getTemplateContent(templateError, errorMessage));
              // Act like we're opening a new colorbox
              $(document).trigger('cbox_complete');
              $.colorbox.resize();
            } else {
              var $formContainer = $('.loyalty_popover__password');
              var $input = $('.form-password', $formContainer);
              $input.addClass('error');
              $('.loyalty_popover__password-empty-error').replaceWith('<div class="loyalty_popover__password-empty-error error">' + errorArr.join('<br />') + '</div>');
              $.colorbox.resize();
            }
          }
        });

        return false;
      });
    },

    /**
   * Initialize the signup form
   * @return {boolean} returns boolean
   */
    initSignUpForm: function() {
      var self = site.offers.loyaltyOffer,
          overlay = self.getOverlayContent();

      // we set visibility to 0 onOpen, to avoid "sliding" while the css is loading
      // now we will:
      // 1) fade them both out
      $('.loyalty-offer-overlay #cboxContent,#cboxOverlay').fadeOut(1, function() {
        // 2) set visibility on and fade in the overelay
        $('#cboxOverlay').css('visibility', 'visible').fadeIn(500, function() {
          // 3) set visibility and fade in the content (very quickly)
          $('.loyalty-offer-overlay #cboxContent').css('visibility', 'visible').fadeIn(100);
        });
      });

      $('#loyalty_popover__checkbox').on('click', function() {
        $('.LOYALTY_PC_EMAIL_PROMOTIONS').val(this.checked ? 1 : 0);
      });

      if (loyaltySignupPopup.skip_native_validation) {
        overlay.find('form').attr("novalidate", "true");
      }

      overlay.find('form').on('submit', function(e) {
        e.preventDefault();
        var form = $(this),
            fields = form.find('input'),
            paramObj = {};

        $.each(fields, function(index, field) {
          paramObj[field.name] = field.type === 'checkbox' && !field.checked ? '' : field.value;
        });

        // Enable CPF fields for BR loyalty enrollment.
        var loyaltyCpf = $('.loyalty-checkbox').attr('data-test-id');
        if (loyaltyCpf) {
          paramObj['FEDERAL_DOC_NUMBER'] = paramObj['FEDERAL_ID'] = paramObj['FEDERAL_ID1'];
          paramObj['FEDERAL_EMAIL_ADDRESS'] = paramObj['PC_EMAIL_ADDRESS'];
        }

        var privacy_field = $('.loyalty_popover__signup input[name="ACCEPTED_PRIVACY_POLICY"]');
        if (privacy_field !== undefined && privacy_field.length > 0) {
          var isUncheckedBox = privacy_field.is(':checkbox') && !privacy_field.prop('checked');
          paramObj['ACCEPTED_PRIVACY_POLICY'] = isUncheckedBox ? '' : privacy_field.val();
        }

        if (loyaltySignupPopup.skip_join_action) { // LOYAL-7662
          var emailAddress = paramObj['PC_EMAIL_ADDRESS'];
          paramObj = {};
          paramObj['EMAIL_ADDRESS'] = emailAddress;
        }

        if (loyaltySignupPopup.skip_join_action) {
          var prependContent = '<input type="hidden" name="LOYALTY_EMAIL" value="' + emailAddress + '"/>';
          $(form)
            .off('submit')
            .attr('action', window.location.origin + '/' + loyaltySettings.registration_endpoint)
            .attr('method', 'POST')
            .prepend(prependContent)
            .trigger('submit');
          return true;
        }

        if (loyaltySignupPopup.care_redirect) {
          paramObj['care_redirect'] = 1;
        }

        // submit form
        generic.jsonrpc.fetch({
          method: 'rpc.form',
          params: [paramObj],
          onSuccess: function(jsonRpcResponse) {
            var response = jsonRpcResponse.getData(),
                userinfo = response.userinfo || {};

            if (loyaltySignupPopup.skip_native_validation) {
              var prepopulationField = userinfo.registered_user ? '<input type="hidden" name="LOYALTY_EMAIL" value="' + paramObj['PC_EMAIL_ADDRESS'] + '"/>' : '';

              $(form)
                .off('submit')
                .attr('action', window.location.origin + '/' + loyaltySettings.registration_endpoint)
                .attr('method', 'POST')
                .prepend(prepopulationField)
                .trigger('submit');
                return true;
            }

            if (loyaltySignupPopup.care_redirect) {
              var prepopulationFieldCare = userinfo.registered_user ?
                '<input type="hidden" name="_SUBMIT" value="care_redirect">'
                + ' <input type="hidden" id="js-care-login-signin-email" name="signin_email" value="' + paramObj['PC_EMAIL_ADDRESS'] + '" />'
                + ' <input type="hidden" name="RETURN_URL" value="' + window.location.origin + '" />'
                :
                '<input type="hidden" name="_SUBMIT" value="care_redirect">'
                + ' <input type="hidden" id="js-care-login-signup-email" name="signup_email" value="' + paramObj['PC_EMAIL_ADDRESS'] + '" />'
                + ' <input type="hidden" name="user_intent" value="signup" />'
                + ' <input type="hidden" name="RETURN_URL" value="' + window.location.origin + '" />';

              $(form)
                .off('submit')
                .attr('action', window.location.origin + '/' + loyaltySettings.registration_endpoint)
                .attr('method', 'POST')
                .prepend(prepopulationFieldCare)
                .trigger('submit');

              return true;
            }

            site.offers.loyaltyOffer.triggerLoyaltyEnrollmentTemplates(userinfo);
            $.colorbox.resize();
          },
          onFailure: function(jsonRpcResponse) {
            var messages = jsonRpcResponse.getMessages();

            // Get the error messages from the rpc response, and use them in the error template
            var tmp = [],
                out = '',
                proceed = 1;
            var messageKey = {
              'required.loyalty_active_flag.loyalty_email_signup': 1,
              'required.pc_email_address.loyalty_email_signup': 1,
              'invalid.pc_email_address.loyalty_email_signup': 1,
              'required.pc_email_promotions.loyalty_email_signup': 1,
              'required.accepted_loyalty_terms.loyalty_email_signup': 1,
              'required.accepted_privacy_policy.loyalty_email_signup': 1,
              'required.cpf.federal_id_check': 1,
              'required.full_name.loyalty_email_signup': 1,
              'unique.cpf.federal_id_check': 1,
              'user.query_by_email_address.email_address_required': 1,
              '.cpf.federal_id_check': 1 };
            $.each(messages, function(i, message) {
              tmp.push(message.text);
              if (messageKey[message.key]) {
                var $formContainer = $('.loyalty_popover__submit-container');
                var $input = $('.form-text', $formContainer);
                $input.addClass('error');
                $('.loyalty_popover__email-empty-error').replaceWith('<div class="loyalty_popover__email-empty-error error">' + message.text + '</div>');
                $.colorbox.resize();
                proceed = 0;
              }
            });

            if (proceed) {
              if (tmp.length > 0) {
                out = '<p>' + tmp.join('<br />') + '</p>';
              }

              // Populate the template with an error message
              var b = self.behavior,
                  t = b.templates.error,
                  d = { message: out.length > 0 ? out : "<p>There's been an error.</p>" };

              overlay
                .empty()
                .append(b.getTemplateContent(t, d));
              // Act like we're opening a new colorbox
              $(document).trigger('cbox_complete');
              $.colorbox.resize();
            } else {
            //
            }
          }
        });

        return false;
      });
    }

  };
})(jQuery);
